<template>
  <div class="wrapper" :class="'wrapper_'+cur" style="min-height:100vh">
      <common-head></common-head>
      <!-- title -->
      <div class="title-wrapper">
          <div class="title-img-wrapper">
              <router-link to="/index">
                  <img class="title-img" src="../../assets/img/logo.png" alt="大大买钢">
              </router-link>
               <span class="title-dec">供应链服务</span>
          </div>
          <div class="title-tab">
              <div class="tab-item" @click="cur = index" v-for="(item,index) in tabs" :key="index" :class="cur == index?'tab-item-active':''">{{item}}</div>
          </div>
      </div>
      <div class="container-wrapper">
          <ddyt v-if="cur == 0"></ddyt>
          <ddsb v-else-if="cur == 1"></ddsb>
          <snagxiangLoad v-else-if="cur == 2"></snagxiangLoad>
          <zxLoan v-else-if="cur == 3"></zxLoan>
          <silverDiscountMarket v-else-if="cur == 4"></silverDiscountMarket>
          <taxLoan v-else-if="cur == 5"></taxLoan>
          <factoringFinance v-else-if="cur == 6"></factoringFinance>
      </div>
  </div>
</template>
<script>
import commonHead from '../../components/headerMark.vue'
import ddyt from './components/ddyt.vue'
import ddsb from './components/ddsb.vue'
import snagxiangLoad from './components/sanxiangLoan.vue'
import zxLoan from './components/zxLoan.vue'
import silverDiscountMarket from './components/silverDiscountMarket.vue'
import taxLoan from './components/taxLoan.vue'
import factoringFinance from './components/factoringFinance.vue'
export default {
  name: "",
  components:{ commonHead,ddyt,ddsb,snagxiangLoad,zxLoan,silverDiscountMarket,taxLoan,factoringFinance},
  data() {
    return {
        tabs:['大大银条','大大赊呗','三湘贷','中信贷','银票贴现市场','税金贷','保理融'],
        cur:0
    };
  },
  created() {
      if(this.$route.query.cur){
          this.cur = this.$route.query.cur
      }
  },
};
</script>
<style lang="scss" scoped>
.wrapper{
    width: 100%;
    height: 100%;

    .title-wrapper{
        width: 1200px;
        margin: 16px auto 0 auto;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        .title-img-wrapper{
            width:270px;
            height: 59px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title-img{
                width: 173px;
                height: 59px;
            }
            .title-dec{
                font-size: 16px;
            }
        }
        .title-tab{
            width: 770px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .tab-item{
                padding:3px 12px;
                border: 1px solid transparent;
                border-radius: 30px;
                user-select: none;
                cursor:pointer;
            }
            .tab-item:hover{
                border: 1px solid #fff;
            }
            .tab-item-active{
                border: 1px solid #fff;
            }
        }
    }
    .container-wrapper{
        // width: 1200px;
        margin: 0 auto;
    }
}
.wrapper_0{
    background: url(../../assets/img/chain_bg_0.png) no-repeat;
    background-size:100% 100%;
}
.wrapper_1{
        background: url(../../assets/img/chain_bg_1.png) repeat fixed;
        background-size: cover;
        background-position: center center;
        height: 100%;
        overflow-y: auto;
}
.wrapper_2{
    background: url(../../assets/img/chain_bg_2.png) repeat fixed;
        background-size: cover;
        background-position: center center;
        height: 100%;
        overflow-y: auto;
}
.wrapper_3{
    background: url(../../assets/img/chain_bg_3.png) repeat fixed;
        background-size: cover;
        background-position: center center;
        height: 100%;
        overflow-y: auto;
}
.wrapper_4{
    background: url(../../assets/img/chain_bg_4.png) repeat fixed;
        background-size: cover;
        background-position: center center;
        height: 100%;
        overflow-y: auto;
}
.wrapper_5{
    background: url(../../assets/img/chain_bg_5.png) repeat fixed;
        background-size: cover;
        background-position: center center;
        height: 100%;
        overflow-y: auto;
}
.wrapper_6{
    background: url(../../assets/img/chain_bg_6.png) repeat fixed;
        background-size: cover;
        background-position: center center;
        height: 100%;
        overflow-y: auto;
}
</style>
