<template>
  <div class="ddyt-wrapper">
    <div class="content_boxNew">
      <div class="yt_title">大大银条</div>
      <div class="title_img"></div>
      <div class="yt_text">
        利息超低 日利率万3
        <br />
        借款期限：一个月 授信期限：一年有效
        <br />
        还款方式：按天计息，随借随还，到期还本付息
      </div>
      <div class="img_list_box">
        <div class="list" v-for="(item, index) in icons" :key="index">
          <div :class="item.className"></div>
          <div class="" style="margin-top: 5px">{{ item.name }}</div>
        </div>
      </div>
      <div class="ddyt_button">
        <a href="https://work.ddmg.com//#/login?front_path=index%2Fbig_gold_suit%2FsilverBar"
          >登录查看</a
        >
      </div>
    </div>
    <div class="ddyt_img_box">
      <div class="border1"></div>
      <div class="img1"></div>
      <div class="img2"></div>
      <div class="img3"></div>
      <div class="img4"></div>
      <div class="img5"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      icons: [
        { className: "img_box1", name: "最高100万额度" },
        { className: "img_box2", name: "随借随还" },
        { className: "img_box3", name: "急速审核" },
      ],
    };
  },
};
</script>
<style lang='scss' scoped>
.ddyt-wrapper {
  width: 1230px;
  height: 730px;
  margin: 0px auto;
  position: relative;
  .content_boxNew {
    width: 830px;
    margin-top: 40px;
    padding-top: 142px;
    background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/Goldclothes_img9.png)
      no-repeat center;
    background-size: 100%;
    background-position-y: top;
    .yt_title {
      font-size: 110px;
      color: #ddc59a;
      margin-left: 45px;
      letter-spacing: 10px;
      z-index: 2;
      position: relative;
    }
    .title_img {
      height: 64px;
      width: 271px;
      background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/titleimg.png)
        no-repeat center;
      position: absolute;
      top: 150px;
      left: 321px;
      z-index: 1;
    }
    .yt_text {
      font-size: 22px;
      color: #ffffff;
      width: 520px;
      height: 80px;
      letter-spacing: 1px;
      margin-left: 30px;
      line-height: 40px;
    }
    .img_list_box {
      width: 750px;
      height: 90px;
      margin-top: 60px;
      margin-left: -70px;
      .list {
        width: 33.3%;
        height: 100%;
        float: left;
        font-size: 22px;
        text-align: center;
        color: white;
        .img_box1 {
          width: 54px;
          height: 62px;
          margin: 0px auto;
          background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/listImg1.png)
            no-repeat center;
          background-size: 100%;
        }
        .img_box2 {
          width: 62px;
          height: 62px;
          margin: 0px auto;
          background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/listImg2.png)
            no-repeat center;
        }
        .img_box3 {
          width: 62px;
          height: 62px;
          margin: 0px auto;
          background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/listImg3.png)
            no-repeat center;
        }
      }
    }
    .ddyt_button {
      letter-spacing: 1px;
      width: 140px;
      height: 40px;
      background: linear-gradient(219deg, #f9a412 0%, #f96703 100%);
      opacity: 1;
      border-radius: 40px;
      text-align: center;
      line-height: 40px;
      color: white;
      font-size: 16px;
      margin-left: 215px;
      margin-top: 55px;
      cursor: pointer;
      a {
        text-decoration: none !important;
        color: #ffffff;
      }
    }
  }
  .ddyt_img_box {
    width: 560px;
    height: 560px;
    position: absolute;
    top: 55px;
    right: 0px;
    .border1 {
      position: absolute;
      width: 212px;
      height: 265px;
      border: 1px solid #245fb7;
      left: 38px;
      top: 10px;
      z-index: 1;
      opacity: 0.42;
    }
    .img1 {
      position: relative;
      width: 189px;
      height: 251px;
      background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/s1.png)
        no-repeat center;
      background-size: 100%;
      top: 42px;
      left: 73px;
      float: left;
      z-index: 2;
    }
    .img2 {
      width: 167px;
      height: 112px;
      background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/s2.png)
        no-repeat center;
      background-size: 100%;
      margin-left: 102px;
      float: left;
      z-index: 2;
    }
    .img3 {
      width: 256px;
      height: 177px;
      background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/s3.png)
        no-repeat center;
      background-size: 100%;
      margin-left: 102px;
      margin-top: 10px;
      float: left;
    }
    .img4 {
      width: 378px;
      height: 251px;
      background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/s4.png)
        no-repeat center;
      background-size: 100%;
      margin-top: 10px;
      float: left;
    }
    .img5 {
      width: 167px;
      height: 251px;
      background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/s5.png)
        no-repeat center;
      background-size: 100%;
      margin-top: 10px;
      margin-left: 13px;
      float: left;
    }
  }
}
</style>