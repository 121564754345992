<template>
  <div class="Goldclothes_content">
    <div class="content_box">
      <h2>大大金条保理融</h2>
      <div class="box_margin">
        <div class="box_left">
          <h1>保理融</h1>
          <h5>针对EOC用户的资产负债、经营数据，通过授信模型评估后，给予向上游采购或向下游垫资业务的数据类金融产品。</h5>
          <h5><span>资金方:中汇泽</span></h5>
        </div>
        <div class="box_right">
          <h5>最高限额：5000000元</h5>
          <h5>借款期限：30天</h5>
          <h5>还款方式：到期还本付息</h5>
          <h5>利率：日利率 0.0400%起</h5>
          <h5>服务费率：0.0000%（年利率）</h5>
          <h5>保证金：0%(借款金额)</h5>
        </div>
      </div>
      <div class="box_bottom">
        <router-link :to="{path:'/supplyChain/details/'+4}">
            <button>查看详情</button>
        </router-link>
      </div>
    </div>
    <div class="Goldclothes_bottom1">
      <div class="bottom_content">
        <div class="two lable_3 dis_none">
          <img
            src="https://ddmallimg.ddmg.com/public/assets/img/new_image/Credit.png"
            alt="大大买钢"
          />
          <h1>纯信用</h1>
        </div>
        <div class="two lable_3 dis_none">
          <img
            src="https://ddmallimg.ddmg.com/public/assets/img/new_image/Mortgage.png"
            alt="大大买钢"
          />
          <h1>无抵押</h1>
        </div>
        <div class="two lable_3 dis_none">
          <img
            src="https://ddmallimg.ddmg.com/public/assets/img/new_image/Guarantee.png"
            alt="大大买钢"
          />
          <h1>免担保</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.Goldclothes_content {
  width: 100%;
  margin: 60px auto 0 auto;
  .content_box {
    width: 747px;
    // height: 500px;
    text-align: center;
    padding: 90px 0;
    margin: 0 auto 90px auto;
    background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/Goldclothes_img.png)
      no-repeat center;
    h2 {
      color: #fff;
      font-size: 60px;
      font-weight: normal;
    }
    .box_margin {
      width: 640px;
      height: 200px;
      margin: 20px auto;
      margin-top: 30px;
      display: flex;
      .box_left {
        width: 293px;
        height: 216px;
        border-right: 2px solid #fff;
        text-align: right;
        padding-right: 25px;
        float: left;
        h1 {
          font-size: 46px;
          color: #fbd293;
          margin-top: 33px;
          font-weight: normal;
        }
        h5 {
          font-size: 14px;
          color: #fff;
          margin-top: 21px;
          font-weight: normal;
          span {
            display: block;
            border: 1px solid #fff;
            width: 182px;
            height: 31px;
            float: right;
            font-size: 18px;
            text-align: center;
            line-height: 31px;
            border-radius: 15px;
          }
        }
      }
      .box_right {
        width: 320px;
        height: 216px;
        float: left;
        h5 {
          font-size: 18px;
          text-align: left;
          padding-left: 25px;
          margin-bottom: 16px;
          margin-top: 0px;
          color: #fbd293;
          font-weight: normal;
        }
      }
    }
    .box_bottom {
      width: 320px;
      height: 50px;
      line-height: 50px;
      margin: 50px auto 0 auto;
      button {
        width: 140px;
        height: 40px;
        border-radius: 25px;
        border: none;
        font-size: 16px;
        margin: 0 auto;
        color: #fff;
        cursor: pointer;
        background-image: linear-gradient(to right, #f96703, #f9a412);
      }
    }
  }
  .Goldclothes_bottom1 {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.35);
    .bottom_content {
      width: 1200px;
      height: 200px;
      margin: 0 auto;
      display: flex;
      .two {
        width: 600px;
        height: 105px;
        text-align: center;
        position: relative;
        border-right: 1px dashed #999;
        float: left;
        margin-top: 48px;
        h1 {
          font-weight: normal;
          color: #fff;
          font-size: 22px;
          position: absolute;
          bottom: 0;
          text-align: center;
          width: 100%;
        }
      }
      .two:last-child {
        border-right: none;
      }
    }
  }
}
</style>
