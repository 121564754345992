<template>
  <div class="Goldclothes_content">
    <div class="content_box">
      <h2>大大赊呗</h2>
      <div class="box_margin">
        <div class="box_left">
          <h1>大大赊呗</h1>
          <h5>全新供应链金融产品,实现先提货后付款</h5>
        </div>
        <div class="box_right">
          <h5>纯信用(无抵押,无保证金)</h5>
          <h5>纯线上(移动/pc多端口操作)</h5>
          <h5>额度高(交易数据提升额度)</h5>
          <h5>期限长(授信有效期长达一年)</h5>
          <h5>成本低(用款灵活,随借随还)</h5>
        </div>
      </div>
    </div>
    <div class="Goldclothes_bottom1">
        <div class="bottom1_box"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.Goldclothes_content {
  width: 100%;
  margin: 60px auto 0 auto;
  .content_box {
    width: 747px;
    // height: 500px;
    text-align: center;
    padding: 90px 0;
    margin: 0 auto 90px auto;
    background: url(https://ddmallimg.ddmg.com/public/assets/img/new_image/Goldclothes_img.png) no-repeat center;
    h2 {
      color: #fff;
      font-size: 60px;
      font-weight: normal;
    }
    .box_margin {
      width: 640px;
      height: 200px;
      margin: 20px auto;
      margin-top: 30px;
      display: flex;
      .box_left {
        width: 293px;
        height: 190px;
        border-right: 1px solid #fff;
        text-align: right;
        padding-right: 25px;
        float: left;
        h1 {
          font-size: 46px;
          color: #fbd293;
          margin-top: 33px;
          font-weight: normal;
        }
        h5 {
          font-size: 14px;
          color: #fff;
          margin-top: 21px;
          font-weight: normal;
        }
      }
      .box_right {
        width: 320px;
        height: 190px;
        border-left: 1px solid #fff;
        float: left;
        h5 {
          font-size: 18px;
          text-align: left;
          padding-left: 25px;
          margin-bottom: 16px;
          margin-top: 0px;
          color: #fbd293;
          font-weight: normal;
        }
      }
    }
  }
  .Goldclothes_bottom1{
          width: 100%;
    height: 418px;
    margin: 0 auto;
    background-color: rgba(0,0,0,0.35);
    .bottom1_box{
        width: 1200px;
    height: 418px;
    margin: 0 auto;
    background: url(https://ddmallimg.ddmg.com/public/assets/img/Whitebarimg.png?v=20200414)no-repeat center;
    }
  }
}
</style>